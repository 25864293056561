<template>
  <div>
    <div class="leftcontHead">
      <div class="boxs">
        <div
          class="box"
          :class="index == typeOn ? 'on' : ''"
          v-for="(item, index) in typeList"
          @click="typeClick(index)"
        >
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
      <div class="inpBox">
        <input
          type="text"
          placeholder="请搜索您发布的内容"
          v-model="seachName"
          @keyup.enter="searchClick"
        />
        <div class="ssBox iconfont icon-search" @click="searchClick"></div>
      </div>
    </div>
    <div class="contType">
      <div class="txt w40">产品</div>
      <div class="txt w10">单价</div>
      <div class="txt w10">数量</div>
      <div class="txt w20">订单状态</div>
      <!-- <div class="txt w10">实付款</div> -->
      <div class="txt w20">操作</div>
    </div>
    <div class="contList">
      <Kong v-if="list.length == 0" />
      <div class="cont" v-for="(item, index) in list">
        <div
          class="contHead"
          @click="orderClick(item.id)"
          :class="
            item.status == 1
              ? 'honBag'
              : item.status == 2
              ? 'huangBag'
              : item.status == 3
              ? 'fhBag'
              : item.status == 4
              ? 'fhBag'
              : ''
          "
        >
          <div class="ddBox">
            <div class="time">{{ item.create_time }}</div>
            <div class="txt">订单号:{{ item.ordernum }}</div>
          </div>
          <div class="money">订单总金额：{{ item.price }}元</div>
        </div>
        <div class="contBox" v-for="chr in item.order_car">
          <div class="cpBox w40">
            <div class="img">
              <img
                v-if="chr.part.resources"
                :src="$imgUrl + chr.part.resources[0].path_name"
                alt=""
              />
            </div>
            <div class="box">
              <div class="name">{{ chr.part.name }}</div>
              <div class="numtxt">购买规格：{{ chr.num }}件</div>
              <div class="txts">
                <div class="txt" v-for="zi in chr.specs">{{ zi }}</div>
              </div>
              <div class="fhtxt">发货时间：{{ chr.fhTime }}前发货</div>
            </div>
          </div>
          <div class="text w10">￥{{ chr.part.price }}</div>
          <div class="text w10">{{ chr.num }}</div>
          <div class="text w20">
            {{
              chr.status == 0
                ? "退款审核中"
                : chr.status == 1
                ? "退款审核完成"
                : chr.status == 2
                ? "退货中"
                : chr.status == 3
                ? "已退款"
                : chr.status == -1
                ? "退款失败"
                : item.status == 0
                ? "已取消"
                : item.status == 1
                ? "待付款"
                : item.status == 2
                ? "待发货"
                : item.status == 3
                ? "已发货"
                : item.status == 4
                ? "已完成"
                : "已评价"
            }}
          </div>
          <!-- <div class="text w10">
            <div class="name">￥{{ item.price }}</div>
            <div class="txt">(包邮)</div>
          </div> -->
          <div class="text w20">
            <div class="butBox" @click="plClick(item)" v-if="item.status == 4">
              评论
            </div>
            <template
              v-if="
                chr.status != 0 &&
                chr.status != 1 &&
                chr.status != 2 &&
                chr.status != 3
              "
            >
              <div
                class="butBox"
                @click="tkshowClick(item, chr)"
                v-if="
                  item.status == 2 ||
                  item.status == 3 ||
                  item.status == 4 ||
                  item.status == 5
                "
              >
                退款
              </div>
            </template>
            <div
              class="butBox"
              @click="dhshowClick(item, chr)"
              v-if="chr.status == 1"
            >
              上传物流
            </div>
            <div
              class="butBox"
              @click="shouhuoClick(item, index)"
              v-if="item.status == 3"
            >
              确认收货
            </div>
            <!-- <div class="butBox" @click="orderClick(item.id)">
              订单详情
            </div> -->
          </div>
        </div>
      </div>
      <div class="fenye" v-if="totalCount > 20">
        <a-pagination
          :default-current="page.pageNo"
          v-model="page.pageNo"
          :total="totalCount"
          @change="onChange"
        />
      </div>
    </div>
    <Pingjia v-if="pjShow" @show="delShowClick" />
    <Tuikuan v-if="tkShow" @show="tkdelClick" />
    <a-modal v-model="shouhuovisible" title="提示" @ok="shouhuohandleOk">
      <p>是否确认收货</p>
    </a-modal>
    <Daohao v-if="dhShow" @show="dhdelClick" />
    <!-- <Wuliu v-if="wlShow" @del="wlDelClick" /> -->
  </div>
</template>

<script>
import Pingjia from "../component/pingjia.vue";
import Tuikuan from "../component/tuikuan.vue";
import Daohao from "../component/danhao.vue";
import Wuliu from "../component/wuliu.vue";
import Kong from "../component/kong.vue";
import { Form, Space, Button, Modal } from "ant-design-vue";

export default {
  components: {
    Pingjia,
    Daohao,
    Wuliu,
    Tuikuan,
    Kong,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-button": Button,
    "a-space": Space,
    "a-modal": Modal,
  },
  name: "",
  data() {
    return {
      dhShow: false,
      shouhuovisible: false,
      tkShow: false,
      pjShow: false,
      wlShow: false,
      typeOn: 0,
      typeList: [
        {
          name: "全部",
          id: "",
        },
        {
          name: "待付款",
          id: 1,
        },
        {
          name: "待发货",
          id: 2,
        },
        {
          name: "已发货",
          id: 3,
        },
        {
          name: "已完成",
          id: 4,
        },
        {
          name: "已评价",
          id: 5,
        },
        {
          name: "已取消",
          id: 0,
        },
        {
          name: "已退款",
          id: -1,
        },
      ],
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
      wlId: "",
      seachName: "",
      totalCount: 0,
    };
  },
  created() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.typeOn = this.$route.query.type;
      this.page.status = this.typeList[this.typeOn].id;
    }
    this.listGet();
  },
  methods: {
    searchClick() {
      this.list = [];
      this.page.name = this.seachName;
      this.page.pageNo = 1;
      this.totalCount = 0;
      this.listGet();
    },
    onChange(pageNumber) {
      this.member_forumList = [];
      this.page.pageNo = pageNumber;
      this.listGet();
    },
    dhdelClick(data) {
      this.dhShow = false;
      if (data) {
        data.id = this.wlId;
        this.$post("/web/refund_logistics", data).then((res) => {
          console.log(res);
          if (res.data.status) {
            this.$message.success("提交成功");
            this.list = [];
            this.page.pageNo = 1;
            this.totalCount = 0;
            this.listGet();
          } else {
            this.$message.warning(res.data.msg);
          }
        });
      }
    },
    dhshowClick(item, chr) {
      this.wlId = chr.id;
      this.dhShow = true;
    },
    plClick(item) {
      window.open("/chuantian/#/pinglun?id=" + item.id);
    },
    shouhuohandleOk() {
      this.$post("/web/order_shou", {
        id: Number(this.shId),
      }).then((res) => {
        this.shouhuovisible = false;
        if (res.data.status) {
          this.$message.success("确认收货成功");
          this.list[this.shIndex].status++;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    shouhuoClick(item, i) {
      this.shouhuovisible = true;
      this.shId = item.id;
      this.shIndex = i;
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/order_list", this.page).then((res) => {
        console.log(res.data);
        this.judge = true;
        this.totalCount = res.totalCount ? res.totalCount : 0;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            for (let j = 0; j < e.order_car.length; j++) {
              const ej = e.order_car[j];
              ej.fhTime = this.$moment(res.data.create_time)
                .add(3, "days")
                .format("MM月DD日HH:mm");
              if (ej.spec.spec) {
                ej.spec.spec = JSON.parse(ej.spec.spec);
                ej.specs = [];
                for (const j1 in ej.spec.spec) {
                  if (ej.spec.spec.hasOwnProperty.call(ej.spec.spec, j1)) {
                    const ej1 = ej.spec.spec[j1];
                    if (j1 != "name") {
                      ej.specs.push(ej1);
                    }
                  }
                }
              }
            }
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    typeClick(i) {
      this.typeOn = i;
      this.list = [];
      this.page.pageNo = 1;
      this.page.status = this.typeList[i].id;
      this.totalCount = 0;
      this.listGet();
    },
    confirm() {
      console.log(1);
    },
    cancel() {
      console.log(2);
    },
    tkshowClick(item, chr) {
      console.log(item);
      // this.tkShow = true;
      // window.open("/chuantian/#/tuikuan?id=" + item.id);
      this.$router.push({
        path: "/tuikuan",
        query: {
          id: item.id,
          chrid: chr.id,
        },
      });
    },
    tkdelClick() {
      this.tkShow = false;
    },
    ltjump() {
      this.$router.push({
        path: "/wzCont",
        query: {},
      });
    },
    showClick() {
      this.pjShow = true;
    },
    delShowClick() {
      this.pjShow = false;
    },
    orderClick(id) {
      this.$router.push({
        path: "/orderCont",
        query: {
          id,
        },
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.leftcontHead {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 37px;
  background-color: #fff;
  border-radius: 5px;
  .boxs {
    display: flex;
    align-items: center;
    line-height: 60px;
  }
  .box {
    margin-right: 44px;
    height: 58px;
    cursor: pointer;

    .name {
      font-size: 16px;
    }
  }

  .on {
    border-bottom: 3px solid #f66;

    // transition: all .3s linear;
    .name {
      font-weight: 700;
    }
  }
  .inpBox {
    width: 220px;
    border-radius: 35px;
    height: 35px;
    border: 1px solid #f66;
    position: relative;
    color: #f66;
    overflow: hidden;
    margin-right: 15px;
    input {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 15px;
      color: #f66;
    }
    .ssBox {
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      line-height: 35px;
      font-size: 18px;
      position: absolute;
      cursor: pointer;
    }
  }
}
.contType {
  height: 45px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  box-sizing: border-box;
  border: 1px solid whitesmoke;
  .txt {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.contList {
  margin-top: 10px;
  .cont {
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    border: 1px solid whitesmoke;
    box-sizing: border-box;
    margin-bottom: 10px;
    .contHead {
      height: 45px;
      display: flex;
      background-color: #ececec;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      .ddBox {
        display: flex;
        align-items: center;
        .time {
          font-weight: 700;
        }
        .txt {
          margin-left: 15px;
          color: #888;
        }
      }
      .money {
        font-weight: 600;
        color: rgb(231, 19, 19);
        margin-right: 25px;
      }
    }
    .fhBag {
      background-color: rgb(224, 239, 255);
    }
    .honBag {
      background-color: rgb(255, 224, 224);
    }
    .huangBag {
      background-color: rgb(255, 243, 224);
    }
    .contBox {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      .cpBox {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .img {
          width: 80px;
          border-radius: 5px;
          height: 80px;
          overflow: hidden;
        }
        .box {
          width: calc(100% - 80px);
          height: 80px;
          padding-left: 10px;
          .numtxt {
            font-size: 12px;
            color: #888;
          }
          .txts {
            display: flex;
            align-items: center;
            margin-top: 4px;
            margin-bottom: 1px;
            .txt {
              font-size: 10px;
              padding: 1px 6px;
              border-radius: 4px;
              border: 1px solid #ebebeb;
              margin-right: 5px;
            }
          }
          .fhtxt {
            font-size: 12px;
            color: #333;
          }
        }
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px 0;
        .name {
          font-weight: 600;
        }
        .txt {
          font-weight: 600;
          color: #888;
        }
        .butBox {
          padding: 3px 12px;
          border: 1px solid #c9c9c9;
          border-radius: 4px;
          font-size: 12px;
          cursor: pointer;
          margin-bottom: 10px;
        }
        .butBox:last-child {
          margin-bottom: 0;
        }
      }
    }
    .contBox:last-child {
      border-bottom: none;
    }
  }
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}
</style>
